
import { useMarketStore } from '~/stores/market'

export default {
  name: 'FavoriteLinkAnonym',
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  computed: {
    anonFavoritesCount() {
      return this.marketStore.anonFavoriteList.length
    },
  },
  mounted() {
    if (this.$auth.$storage.getLocalStorage('favorite_list')) {
      this.marketStore.anonFavoriteList =
        this.$auth.$storage.getLocalStorage('favorite_list')
    }
    if (this.$auth.$storage.getLocalStorage('favorite_list_data')) {
      this.marketStore.anonDataLayerFavoriteList =
        this.$auth.$storage.getLocalStorage('favorite_list_data')
    }
  },
}
